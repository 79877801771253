<template>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-3"></div>

      <div class="col-md-6 register-container">
        <div class="card register">
          <div class="card-body">
            <p class="card-title pb-4">{{ $t('views.landing.heading') }}</p>
            <!-- <p class='card__info'>{{ $t('views.landing.date') }}</p>
            <p class='card__info'>{{ $t('views.landing.time') }}</p> -->
            <p
              class="card-text white-text pb-4"
            >{{ $t('views.landing.body') }}</p>

            <button
                class="btn btn-block mt-4 "
                @click="goToLogin()"
              >{{ $t('views.landing.button_login') }}</button>
            <button
                class="btn btn-block mt-4 "
                @click="goToRegister()"
              >{{ $t('views.landing.button') }}</button>
          </div>
        </div>
      </div>

      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        company: '',
        email: '',
        password: '',
        // Added those hard coded as there are not yet implemented in our front end
        company_position: '',
        inviter_id: '',
        accept_terms: false,
        recaptcha_token: ''
      },
      status: '',
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      firmaName: '',
      submitted: false,
      type: 'password',
      eyeClass: 'fa fa-fw fa-eye field-icon toggle-password'
    };
  },
  methods: {
    checkBrowser(){
      if(this.$browserDetect.isOpera){
        if (
          window.confirm(
            'Achtung! Sie nutzen den Opera-Webbrowser zum Abrufen dieser virtuellen Veranstaltung. Bitte nutzen Sie einen moderneren Browser wie Google Chrome oder Mozilla Firefox für fehlerfreie Funktion. Wir empfehlen Ihnen, Ihre Software zu modernisieren und z.B. Google Chrome zu installieren. Drücken Sie "OK" um zur Webseite von Google Chrome zu gelangen.'
          )
        ) {
          window.location.href =
                  'https://www.google.com/chrome/browser/index.html';
        }
      } else if(this.$browserDetect.isIE){
        if (
          window.confirm(
            'Achtung! Sie nutzen den veralteten Internet Explorer-Webbrowser zum Abrufen dieser virtuellen Veranstaltung. Bitte nutzen Sie einen moderneren Browser wie Google Chrome oder Mozilla Firefox für fehlerfreie Funktion. Wir empfehlen Ihnen, Ihre Software zu modernisieren und z.B. Google Chrome zu installieren. Drücken Sie "OK" um zur Webseite von Google Chrome zu gelangen.'
          )
        ) {
          window.location.href =
                  'https://www.google.com/chrome/browser/index.html';
        }
      } else if(this.$browserDetect.isEdge){
        if (
          window.confirm(
            'Achtung! Sie nutzen den Microsoft Edge-Webbrowser in einer älteren Version zum Abrufen dieser virtuellen Veranstaltung. Teile der Funktionen könnten eingeschränkt sein. Bitte nutzen Sie einen moderneren Browser wie Google Chrome oder Mozilla Firefox für fehlerfreie Funktion. Wir empfehlen Ihnen, Ihre Software zu modernisieren und z.B. Google Chrome zu installieren. Drücken Sie "OK" um zur Webseite von Google Chrome zu gelangen.'
          )
        ) {
          window.location.href =
                  'https://www.google.com/chrome/browser/index.html';
        }
      } else if(this.$browserDetect.isIOS){
        if (
          window.confirm(
            'Achtung! Sie nutzen den Safari für iOS Browsern zum Abrufen dieser virtuellen Veranstaltung. Bitte nutzen Sie einen moderneren Browser wie Google Chrome oder Mozilla Firefox für fehlerfreie Funktion. Wir empfehlen Ihnen, Ihre Software zu modernisieren und z.B. Google Chrome zu installieren. Drücken Sie "OK" um zur Webseite von Google Chrome zu gelangen.'
          )
        ) {
          window.location.href =
                  'https://www.google.com/chrome/browser/index.html';
        }
      } else if(this.$browserDetect.isSafari){
        if (
          window.confirm(
            'Sie nutzen Apple Safari zum Abruf der Webseite. Safari unterstützt keine modernen Web-Anwendungen. Bitte drücken Sie auf „OK“, um auf die Download-Seite von Google Chrome zu gelangen, und installieren Sie sich den Google Chrome Webbrowser zum Abruf der Plattform.'
          )
        ) {
          window.location.href =
                  'https://www.google.com/chrome/browser/index.html';
        }
      }
    },
    togglePassword() {
      if (this.eyeClass.includes('fa-eye-slash')) {
        this.eyeClass = this.eyeClass.replace('fa-eye-slash', 'fa-eye');
        document.getElementById('input-pwd').type = 'password';
      } else {
        this.eyeClass = this.eyeClass.replace('fa-eye', 'fa-eye-slash');
        document.getElementById('input-pwd').type = 'text';
      }
    },
    validateForm() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.$refs.recaptcha.execute();
        }
      });
    },
    register() {
      this.$store
        .dispatch('authRegister', this.user)
        .then(() => {
          this.$router.push('/plan');
        })
        .catch();
    },
    onCaptchaVerified(recaptchaToken) {
      this.status = 'submitting';
      this.$refs.recaptcha.reset();
      this.user.recaptcha_token = recaptchaToken;
      this.register();
      this.status = '';
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    goToRegister() {
      this.$router.push('/register');
    },
    goToLogin() {
      this.$router.push('/login');
    }
  },
  created() {
    this.firmaName = this.$route.query.inviter || 'Hafele';
    this.user.inviter_id = this.$route.query.inviter_id || '4';
  },
  mounted() {

    this.checkBrowser();
    let cookieConsentScript = document.createElement('script');
    cookieConsentScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js');
    document.head.appendChild(cookieConsentScript);

    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
    );
    recaptchaScript.setAttribute('async', '');
    recaptchaScript.setAttribute('defer', '');
    document.head.appendChild(recaptchaScript);

    setTimeout(function(){

      window.cookieconsent.initialise({
        palette: {
          popup: {
            background: '#aa0000',
            text: '#ffdddd'
          },
          button: {
            background: '#ff0000'
          }
        },
        showLink: false,
        revocable: false,
        theme: 'edgeless',
        position: 'bottom-left',
        type: 'opt-out',
        content: {
          message: 'Diese Webseite verwendet anonyme Statistik-Cookies. Weitere notwendige Cookies müssen zur Lauffähigkeit der Plattform außerdem bei Registrierung erlaubt werden.',
          dismiss: 'Einverstanden',
          deny: 'Statistik Ablehnen',
          allow: 'Statistik Erlauben'
        }
      });
    }, 3000);
  }

};
</script>

<style lang="scss" scoped>
  @import url("https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css");
.header {
  position: absolute;
  top: 50%;
  left: 50%;
  width:100vw;
  height:100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: -1;
}

// .background{
//     &__text {
//         font-family: "Helvetica Neue";
//         text-transform: none;
//         margin: 0;
//         font-size: 18rem;
//         opacity: 0.5;
//         text-align: left;
//         font-weight: 300;
//         line-height: 18rem;
//         letter-spacing: -5.7px;
//         color: #FFFFFF;
//     }
// }

.footer {
  margin-top: 10px !important;
  margin-bottom: 0p !important;
  font-size: 10px;
  color: white;
  font-weight: 300;
}

header p {
  text-transform: none;
  margin: 0;
  font-size: 250px;
  white-space: nowrap;
  font-weight: 300;
  color: $secondaryColor;
  letter-spacing: -5.7px;
  color: $secondaryColor;
  opacity: 1;
}

.error-mssg {
  font-size: 12px;
}

.toggle-password:hover {
  cursor: pointer;
}

.card-body {
  padding-right: 8%;
  padding-left: 8%;
  :nth-child(2){
          margin-top: 2rem;
        }
}

.card{
    &__info{
        color:#FFFFFF;
        font-weight: bold;
        font-size: 18px;
        line-height: 0.6;
    }
}

.container {
  margin-top: $loginMarginTop;
}

.register {
  background-color: rgba(80, 80, 80, 0.9);
  margin-bottom: 40px !important;
}

.card-title {
  font-weight: 300;
  color: $secondaryColor;
  font-size: 42px;
  padding-bottom: 10px !important;
}

.card-text {
  padding-top: 1rem;
  font-size: 16px !important;
  font-weight: 400;

}

input {
  font-size: 16px !important;
}

.register .text-field {
  border-radius: 0%;
  background-color: rgba(80, 80, 80, 0.9);
  color: white;
  border-color: rgba(255, 255, 255, 0.7);
}

.register ::placeholder {
  color: white;
  opacity: 0.5;
}

.field-icon {
  float: right;
  margin-right: 10px;
  margin-top: -35px;
  position: relative;
  z-index: 2;
  color: white;
  opacity: 0.8;
}

.checkbox-text {
  font-size: 16px;
}

.checkbox-text span {
  font-weight: 700;
}

.register .btn {
  color: black;
  background-color: white;
  height: 37px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  padding-left: 7%;
  padding-top: 1.5%;
  text-transform: none;
  margin-top: 5% !important;
  margin-bottom: 5%;
  border-radius: 0 !important;
}

.register .btn:hover {
  background-color: $secondaryColor;
}

@media screen and (max-device-width: 640px) and (orientation: landscape) {
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }

  .card-text {
    font-size: 10px !important;
  }

  input {
    font-size: 10px !important;
  }

  .register .btn {
    font-size: 10px !important;
    padding-left: 15px !important;
    padding-top: 10px !important;
  }

  .checkbox-text {
    font-size: 10px !important;
  }

  p {
    font-size: 14px !important;
  }
}


@media screen and (max-device-width: 1024px) {
  .card {
    // margin-top: 20% !important;
  }
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
</style>
